<template>
  <div>
    <hr v-if="!spinner"/>
    <b-spinner v-if="spinner" variant="primary" class="spinners"/>
    <div v-if="!spinner">
      <b-row class="mt-1">
        <b-col cols="12">
          <b-card header-bg-variant="cardTitle" header-tag="header">
            <template #header>
              <h4>Kullanıcı Listesi</h4>
            </template>
            <table class="table table-striped mt-1 table-hover table-sm" :key="count">
              <thead>
              <tr>
                <th width="5%" scope="col">KULLANICI ADI</th>
                <th scope="col">HESAP SAHİBİ</th>
                <th scope="col">TELEFON</th>
                <th scope="col">YETKİ TÜRÜ</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(user, index) in this.$store.getters.getUserList" :key="index" class="fw-bold">
                <td class="d-flex">
                  <!--                  <b-avatar variant="primary" :text="user.user.slice(0, 2)"/>-->
                  <p class="mr-1">{{ user.user }}</p>
                  <b-badge v-if="user.admin == 1" variant="bgSuccess"> Sistem Yöneticisi</b-badge>
                  <b-badge :variant="user.pasif == 1 ? 'warning' : 'primary'">
                    {{ user.pasif == 1 ? 'Pasif' : 'Aktif' }}
                  </b-badge>
                </td>
                <td>{{ user.isim }}</td>
                <td>{{ user.tel }}</td>
                <td>
                  <b-badge class="userP" :variant="
                    user.level == 1
                      ? 'bgInfo'
                      : user.level == 2
                        ? 'bgWarning'
                        : 'bgDanger'
                  "
                  >
                    {{
                      user.level == 1
                          ? 'Yönetici'
                          : user.level == 2
                              ? 'Kullanıcı'
                              : ''
                    }}
                  </b-badge>
                </td>
              </tr>
              </tbody>
            </table>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <!--    <b-modal id="pwd-change" cancel-variant="secondary" ok-title="Accept" ok-only hide-footer centered size="xs"-->
    <!--             :title="`Şifre Değiştir (${userName})`"-->
    <!--    >-->
    <!--      <b-row>-->
    <!--        <b-col cols="12">-->
    <!--          <b-form-group label="Yeni Şifre">-->
    <!--            <b-form-input id="basicInput" v-model="newPwd" @keyup.enter="pwdUpdate()"/>-->
    <!--          </b-form-group>-->
    <!--        </b-col>-->
    <!--        <b-col cols="12" class="my-modal-col">-->
    <!--          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="info" @click="pwdUpdate">-->
    <!--            Güncelle-->
    <!--          </b-button>-->
    <!--          <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-bgDanger"-->
    <!--                    @click="$bvModal.hide('pwd-change')"-->
    <!--          >-->
    <!--            İptal-->
    <!--          </b-button>-->
    <!--        </b-col>-->
    <!--      </b-row>-->
    <!--    </b-modal>-->
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import {
  VBModal,
  BProgress,
  BRow,
  BCol,
  BCard,
  BInputGroupAppend,
  BInputGroupPrepend,
  BFormCheckbox,
  BFormCheckboxGroup,
  BTable,
  BModal,
  BImg,
  BInputGroup,
  BContainer,
  BFormInput,
  BPagination,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BFormFile, BSpinner,
} from 'bootstrap-vue'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    VBModal,
    BProgress,
    BButton,
    BContainer,
    BAvatar,
    BCard,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BImg,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormCheckbox,
    BFormCheckboxGroup,
    BTable,
    BModal,
    BInputGroup,
    quillEditor,
    BFormFile,
    vSelect,
    BSpinner,
  },
  data() {
    return {
      spinner: true,
      Deselect: {
        render: (createElement) => createElement('span', ''),
      },
      OpenIndicator: {
        render: (createElement) => createElement('span', ''),
      },
      isEdit: false,
      userName: '',
      userID: '',
      newPwd: '',
      chooseUserID: '',
      count: 0,
      kullaniciAdi: '',
      sifre: '',
      name: '',
      tel: '',
      mail: '',
      tur: {
        title: 'Personel',
        key: 2
      },
      turOption: [
        {
          title: 'Yönetici',
          key: 1
        },
        {
          title: 'Personel',
          key: 2
        },
      ],
    }
  },
  mounted() {
  },
  methods: {
    pwdUpdate() {
      if (this.newPwd) {
        this.$store
            .dispatch('userPwdChange', {
              userID: this.userID,
              sifreDegis: this.newPwd,
            })
            .then((res, position = 'bottom-right') => {
              this.$bvModal.hide('pwd-change'),
                  (this.newPwd = ''),
                  (this.userName = ''),
                  (this.userID = '')
              const mesaj = this.$store.getters.notificationSettings(res)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: mesaj,
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              }, { position })
            })
            .catch(err => {
              const mesaj = this.$store.getters.notificationSettings(err)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            })
        this.count = this.count + 1
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Şifre Boş Geçilemez',
            icon: 'ThumbsDownIcon',
            variant: 'danger',
          },
        })
      }
    },
    readSifreUpdate(item) {
      this.userID = item.userID
      this.userName = item.user
    },
  },
  created() {
    setTimeout(() => {
      this.spinner = false
    }, 200)
    this.$store.dispatch('userList')
        .then(res => {
          const mesaj = this.$store.getters.notificationSettings(res)
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Başarılı',
                text: mesaj,
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            })
          }
        })
        .catch(err => {
          const mesaj = this.$store.getters.notificationSettings(err)
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          }
        })
  },
  beforeDestroy() {
    this.$store.commit('userListAction', 'remove')
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
}
</script>

<style scoped>
.my-modal-col {
  justify-content: space-around;
  display: flex;
}

.fw-bold {
  font-weight: 900;
}

.my-rows {
  align-items: center !important;
}

.badge {
  padding: 0.3rem;
  border-radius: 15px;
  margin-top: 0.1rem;
}

p {
  margin-top: 0;
  margin-bottom: 0;
  text-align: start;
}

@keyframes mymove {
  0% {
    background-color: pink;
  }

  25% {
    background-color: pink;
  }

  50% {
    background-color: #00ffd1;
  }

  75% {
    background-color: #00ffd1;
  }

  100% {
    background-color: #00ffd1;
    width: 480px;
  }
}

.editOnPrgoress {
  width: 0px;
  height: 4px;
  background: #38e54d;
  position: relative;
  animation: mymove 4000ms infinite;
  border-radius: 20px;
  transform: translateY(20px);
  /* animation-iteration-count: 1 !important; */
}

.odaKayitRow {
  justify-content: space-around !important;
}

.table td[data-v-199ebc45][data-v-199ebc45] {
  text-align: center;
}

[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  background-color: #31526f;
  color: white;
  padding: 0.6rem;
  border: none;
  text-align: center;
}

[dir] .table th[data-v-199ebc45],
[dir] .table td[data-v-199ebc45] {
  padding: 0.1rem;
}

.row {
  justify-content: end;
}

[dir] .table th,
[dir] .table td {
  padding: 0.72rem;
  border-top: 1px solid #ebe9f1;
  max-width: 450px !important;
}

.card-header {
  background-color: #2e83c9;
  height: 30px;
  position: relative;
}

.card-header h4 {
  position: absolute;
  color: white;
  margin-top: 10px;
}

[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  background-color: #0ea9d7;
  color: white;
}

.userP {
  padding: 5px;
}

tr {
  text-align: center !important;
}

.b-avatar, b-badge {
  margin: 0 20px !important;
}

.spinners {
  position: absolute !important;
  margin-left: 47% !important;
  margin-top: 15% !important;
  width: 3rem !important;
  height: 3rem !important;
  z-index: 900 !important;
}
</style>
<style>

[dir] .table th[data-v-247cb7f1],
[dir] .table td[data-v-247cb7f1] {
  padding: 0.2rem;
  border-top: 1px solid #ebe9f1;
  max-width: 450px !important;
  text-align: center;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
